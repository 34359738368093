// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cam-neely-foundation-js": () => import("./../../../src/pages/cam-neely-foundation.js" /* webpackChunkName: "component---src-pages-cam-neely-foundation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jimmy-fund-js": () => import("./../../../src/pages/jimmy-fund.js" /* webpackChunkName: "component---src-pages-jimmy-fund-js" */),
  "component---src-pages-memorial-js": () => import("./../../../src/pages/memorial.js" /* webpackChunkName: "component---src-pages-memorial-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */)
}

